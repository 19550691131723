import React, { FC, createContext } from "react";

export type Domain = "mikafood.ru";
export type CityName = "Магнитогорск";
export type CityNameForSeo = "Магнитогорске";
export type CityNameWithRegion = "Магнитогорск";

export enum Metrica {
  Magnitogorsk = 56971312,
}

type Phone = {
  href: string;
  number: string;
};

export interface City {
  domain: Domain;
  cityName: CityName;
  cityNameForSeo: CityNameForSeo;
  cityNameWithRegion: CityNameWithRegion;
  phone: { header: Phone; footer: Phone; default: Phone; delivery: Phone };
  legalInfo: string[];
  workingHours: string;
  ymAccountId: Metrica;
}

export const CityContext = createContext<City>({} as City);

export const CityProvider: FC<{ host: string }> = ({ host, children }) => {
  const defaultMgnPhone: Phone = {
    href: "tel:83519464676",
    number: "8 (3519) 46-46-76",
  };

  const secondMgnPhone: Phone = {
    href: "tel:83519540000",
    number: "8 (3519) 54-00-00",
  };

  const getCity = (): City => {
    return {
      domain: "mikafood.ru",
      cityName: "Магнитогорск",
      cityNameForSeo: "Магнитогорске",
      cityNameWithRegion: "Магнитогорск",
      workingHours: "с 8:00 до 24:00",
      ymAccountId: Metrica.Magnitogorsk,
      phone: {
        header: secondMgnPhone,
        footer: defaultMgnPhone,
        default: defaultMgnPhone,
        delivery: secondMgnPhone,
      },
      legalInfo: [
        `ИП Багдасарян А.Р  127015, г. Москва, Новодмитровская улица, 2к7
            ИНН 744600235454, ОГРНИП 304744535700131`,
        `ИП Пьянкова Н.М. 455021, Челябинская обл., г. Магнитогорск, улица Тевосяна, 25
            ИНН 744503061280, ОГРН 321745600000176`,
        `ИП Налбандян Л.Э. 455000, Челябинская обл., г. Магнитогорск, Советская улица, 166
            ИНН 745501327579, ОГРН 321745600000209`,
        `ИП Гришакова Н.С. 455000, Челябинская обл., г. Магнитогорск, Комсомольская улица, 14
            ИНН 744405758764, ОГРН 321745600139611`,
        `ИП Гусева Е.С. 455008, Челябинская обл., г. Магнитогорск, ул. Зелёный Лог 33 к.1,
            ИНН 744515853153, ОГРН 324745600177083`,
      ],
    };
  };

  const data = getCity();
  return <CityContext.Provider value={data}>{children}</CityContext.Provider>;
};
